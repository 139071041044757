import { Card, Col, FieldWrapper, Grid, Heading, Label, NumberInput, Paragraph, Row, Text, ToggleButton } from '@mlc/web-ui-toolkit';
import classNames from 'classnames';
import classnames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppThemeContext } from '../../../../context/AppThemeContext';
import { Calculator } from '../../../../logic/domains/calculator.domain';
import { asRoundedCurrency } from '../../../../logic/functions/formatter';
import { logAction } from '../../../../logic/functions/logging.function';
import { convertToNumber, focusFirstError, focusHeading } from '../../../../logic/functions/util';
import { commonClasses } from '../../Common/Common.elements';
import { KateCard, NavButtonGroup, StyledModal } from '../../Common/Common.style';
import FieldWithTooltip from '../../Common/FieldWithTooltip';
import InfoPopup from '../../Common/InfoPopup';
import { BackButton, NextButton } from '../../Common/NavButtons';
import { StepInput } from '../CalculatorSteps/CalculatorSteps.style';
import { DetailsToggleGroup } from '../CalculatorTab.style';
import aboutYouTestIds from './AboutYou.elements';
import { AboutYouHeader, AboutYouInput, AnnuallyText, OtherSuperDisclaimerCard, SuperSizerWrapper, WrapTooltipText } from './AboutYou.style';

type Props = {
    formikProps: any;
    setUpdatingMember: () => void;
    handleNextStep: () => void;
    handlePreviousStep: () => void;
    calculatorFrozen?: boolean;
    localCalculatorValues: any;
    setLocalCalculatorValues: (values: any) => void;
    canShowDetailsWarnings: boolean;
    setCanShowDetailsWarnings: (canShow: boolean) => void;
    spin: string;
}

type WarningContent = {
    content: any;
    btns: any[];
}

//calculator step 2 of 8
const AboutYou = ({ formikProps, setUpdatingMember, handleNextStep, handlePreviousStep, calculatorFrozen, localCalculatorValues, setLocalCalculatorValues, canShowDetailsWarnings, setCanShowDetailsWarnings }: Props) => {
    const { staticContent, isMksf } = useContext(AppThemeContext);
    const { setFieldValue, errors } = formikProps;
    const values: Calculator = formikProps.values;
    const content: any = staticContent.aboutYou(values.productName);
    const warnings: any = content.warnings;
    const [isSuperMessageOpen, setIsSuperMessageOpen] = useState(false);
    const [isContributionWarningOpen, setIsContributionWarningOpen] = useState(false);
    const [isWarningOpen, setIsWarningOpen] = useState(false);
    const [warningContent, setWarningContent] = useState<WarningContent>({ content: '', btns: [] });
    const [frozenFromOtherSuper, setFrozenFromOtherSuper] = useState(false);
    const [nonSuperInputsFrozen, setNonSuperInputsFrozen] = useState(false);
    const [noOtherIncomeError, setNoOtherIncomeError] = useState(false);
    const [noOtherSuperError, setNoOtherSuperError] = useState(false);
    const [monthlyContributionError, setMonthlyContributionError] = useState(false);
    const [canShowNoIncomeError, setCanShowNoIncomeError] = useState(false);
    const [isContributionsMessageOpen, setIsContributionsMessageOpen] = useState(false);
    const contributionsRef = useRef<any>();
    const [headerContent, setHeaderContent] = useState({ image: '', balance: "" });

    useEffect(() => {
        setNonSuperInputsFrozen(calculatorFrozen || localCalculatorValues.hasVoluntaryContributions === 'y' || (frozenFromOtherSuper && localCalculatorValues.hasOtherSuper === 'y' && (values.otherSuper + values.currentSuperBalance >= values.maxAccountBalance)));
    }, [calculatorFrozen, frozenFromOtherSuper, localCalculatorValues.hasOtherSuper, values.otherSuper, values.currentSuperBalance, values.maxAccountBalance, localCalculatorValues.hasVoluntaryContributions]);

    useEffect(() => {
        setLocalCalculatorValues({
            hasOtherIncome: values.otherEmploymentIncome > 0 || values.otherTaxableIncome > 0 ? 'y' : 'n',
            hasOtherSuper: values.otherSuper > 0 ? 'y' : 'n'
        });
        for (const key of Object.keys(content.ageContent)) {
            if (values.age >= parseInt(key)) {
                setHeaderContent({
                    image: content.ageContent[key].image,
                    balance: content.ageContent[key].balance
                })
            }
        }
        focusHeading();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeContributionsMessage = () => {
        setIsContributionsMessageOpen(false);
        setTimeout(() => {
            contributionsRef.current && contributionsRef.current!.focus();
        }, 100);
    }

    const getWarningBtns = () => ({
        insurance: [
            {
                onClick: () => {
                    setIsWarningOpen(false);
                    handleNextStep();
                },
                content: "Continue",
                buttonType: "primary",
            },
            {
                onClick: () => {
                    setIsWarningOpen(false);
                },
                content: "Update your details",
                buttonType: "tertiary",
            },
        ],
        mcb: [
            {
                onClick: () => setIsWarningOpen(false),
                content: "Ok"
            },
        ],
        salary: [
            {
                onClick: () => setIsWarningOpen(false),
                content: "Update your details"
            },
        ],
    });

    useEffect(() => {
        const displayWarnings = () => {
            const displayedOtherEmployment = localCalculatorValues.hasOtherIncome === 'y' ? values.otherEmploymentIncome : 0;
            const displayedOtherTaxable = localCalculatorValues.hasOtherIncome === 'y' ? values.otherTaxableIncome : 0;
            const totalIncome = values.salary + displayedOtherEmployment + displayedOtherTaxable;
            const totalSg = ((values.salary * values.employerContribution) / 100) + ((displayedOtherEmployment * values.sgRateFromReferenceData) / 100);
            const totalSuper = localCalculatorValues.hasOtherSuper === "y" ? values.otherSuper + values.currentSuperBalance : 0;
            let shouldShowWarning = true;
            const warningBtns = getWarningBtns();
            if (values.contributionWarning) {
                // high contributions
                setIsContributionWarningOpen(true);
                setFrozenFromOtherSuper(false);
                shouldShowWarning = false;
            } else if (values.insurancePremiums > totalSg) {
                // insurance premiums > SG contributions
                if (isMksf) {
                    if (values.insurancePremiums !== 0) {
                        setWarningContent({
                            content: warnings?.highPremiums,
                            btns: warningBtns.insurance
                        });
                    }
                } else {
                    if (values.employerInsurancePremium === 0) {
                        setWarningContent({
                            content: warnings.aboveSgNoEmployerInsurance,
                            btns: warningBtns.insurance
                        });
                    } else {
                        setWarningContent({
                            content: warnings.aboveSgEmployerInsurance,
                            btns: warningBtns.insurance
                        });
                    }
                }
            } else if (totalSg > Math.floor(values.mcbCapAmount)) {
                // high salary (MCB)
                setWarningContent({
                    content: warnings.mcb,
                    btns: warningBtns.mcb
                });
            } else if (totalSuper >= values.maxAccountBalance) {
                // high total super
                setIsSuperMessageOpen(true);
                shouldShowWarning = false;
                setFrozenFromOtherSuper(true);
            } else if (totalIncome < (values.currentContribution.salarySacrifice + values.currentContribution.personal)) {
                setWarningContent({
                    content: warnings.lowSalary,
                    btns: warningBtns.salary
                });
            } else if ((!isMksf && (values.insurancePremiums === 0 || values.employerInsurancePremium === 0))
                || (isMksf && values.insurancePremiums === 0)) {
                // insurance premium = 0
                if (isMksf) {
                    if (values.insurancePremiums === 0) {
                        setWarningContent({
                            content: warnings?.noPremiums,
                            btns: warningBtns.insurance
                        });
                    }
                } else {
                    if (values.insurancePremiums === 0 && values.employerInsurancePremium === 0) {
                        setWarningContent({
                            content: warnings.noMemberInsurance,
                            btns: warningBtns.insurance
                        });
                    } else if (values.insurancePremiums === 0 && values.employerInsurancePremium !== 0) {
                        setWarningContent({
                            content: warnings.noEmployerPremiums,
                            btns: warningBtns.insurance
                        });
                    } else {
                        setWarningContent({
                            content: warnings.noInsurancePremiums,
                            btns: warningBtns.insurance
                        });
                    }
                }
            } else {
                shouldShowWarning = false;
                handleNextStep();
            }

            if (shouldShowWarning) {
                setFrozenFromOtherSuper(false);
                setIsWarningOpen(true);
            }
        }

        if (canShowDetailsWarnings) {
            displayWarnings();
            setCanShowDetailsWarnings(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canShowDetailsWarnings])

    return (
        <>            
            <KateCard>
                <Grid>
                    <Heading marginBottom="5px" marginTop="20px" fontSize="large" el="span" fontWeight="semibold" display="block">
                        {values.firstName}, are you making the most of your super?
                    </Heading>
                    <Paragraph>Tell us a little bit about yourself and we'll tell you if you're on track to achieve your retirement goals.</Paragraph>
                    <AboutYouHeader>
                        <img alt="" height="200px" src={headerContent.image} />
                        <SuperSizerWrapper className="wrapper">
                            <Text className={classNames("yourSuper", "twoLines")}>Your super balance</Text>
                            <Heading className="yourSuper" fontSize="25px" fontWeight="700" el="div">{asRoundedCurrency(values.currentSuperBalance)}</Heading>
                            <Text>
                                Average of people like you
                                <InfoPopup
                                    label="Average of people like you"
                                    content={content.likeYouDisclaimer}
                                    dataId={aboutYouTestIds.peopleLikeYouTT}
                                    log="LIKE_YOU_TT"
                                />
                            </Text>
                            <Heading fontSize="25px" fontWeight="700" el="div">{headerContent.balance}</Heading>
                        </SuperSizerWrapper>
                    </AboutYouHeader>
                </Grid>
            </KateCard>
            <Grid>
                <Card marginTop={3} marginBottom={4}>
                    <Paragraph>Any personal information you enter into this calculator will not be automatically updated on your super account. To update your details call us on {content.updateAccountPhoneNo}.</Paragraph>
                </Card>
                <Card>
                    <Col>
                        <Row>
                            <FieldWithTooltip
                                label="Your salary (before tax)"
                                id={aboutYouTestIds.yourSalaryInputField}
                                error={errors.noEmployedIncome}
                                isInvalid={canShowNoIncomeError && errors.noEmployedIncome}
                                tooltipContent="Your salary is generally what you earn from ordinary hours of work, and it includes commissions, shift loadings and allowances. We have assumed your employer pays the minimum Superannuation Guarantee on this value."
                                tooltipId={aboutYouTestIds.yourSalaryTooltip}
                                tooltipLabel="Information about salary"
                                tooltipLog="SALARY_TT"
                            >
                                <AboutYouInput>
                                    <NumberInput
                                        prefix="$"
                                        value={Math.trunc(values.salary).toString()}
                                        onBlur={(e: any) => {
                                            setFieldValue("salary", convertToNumber(e.target.value));
                                        }}
                                        allowDecimal={false}
                                        allowLeadingZeroes={true}
                                        integerLimit={9}
                                        className={classnames((canShowNoIncomeError && errors.noEmployedIncome) ? commonClasses.validationError : '', nonSuperInputsFrozen ? 'disabledForm' : '')}
                                        readOnly={nonSuperInputsFrozen}
                                        aria-readonly={nonSuperInputsFrozen}
                                        data-analytics-capture-value
                                        variant="boxed"
                                        data-id={aboutYouTestIds.yourSalaryInput}
                                        aria-labelledby={`${aboutYouTestIds.yourSalaryInput}-label`}
                                        aria-describedby={`${aboutYouTestIds.yourSalaryInput}-error`}
                                        aria-invalid={canShowNoIncomeError && errors.noEmployedIncome}
                                    />
                                    <AnnuallyText>
                                        annually
                                    </AnnuallyText>
                                </AboutYouInput>
                            </FieldWithTooltip>
                        </Row>
                        <Row>
                            <FieldWithTooltip
                                label={<WrapTooltipText>Super contribution paid by your employer</WrapTooltipText>}
                                error={`Please enter a value between ${values.sgRateFromReferenceData}% and 25%.`}
                                tooltipContent="This includes Superannuation Guarantee but excludes salary sacrifice."
                                tooltipId={aboutYouTestIds.employerContributionTooltip}
                                tooltipLabel="Information about employer contribution"
                                tooltipLog="EMPLOYER_CONTRIBUTION_TT"
                                id={aboutYouTestIds.employerContributionInputField}
                                isInvalid={errors.employerContribution}
                            >
                                <AboutYouInput>
                                    <NumberInput
                                        suffix="%"
                                        value={values.employerContribution.toString()}
                                        onBlur={(e: any) => {
                                            setFieldValue("employerContribution", convertToNumber(e.target.value));
                                        }}
                                        allowLeadingZeroes={true}
                                        integerLimit={9}
                                        className={classnames(errors.employerContribution ? commonClasses.validationError : '', nonSuperInputsFrozen ? 'disabledForm' : '')}
                                        readOnly={nonSuperInputsFrozen}
                                        aria-readonly={nonSuperInputsFrozen}
                                        data-analytics-capture-value
                                        variant="boxed"
                                        data-id={aboutYouTestIds.employerContributionInput}
                                        aria-labelledby={`${aboutYouTestIds.employerContributionInput}-label`}
                                        aria-describedby={`${aboutYouTestIds.employerContributionInput}-error`}
                                        aria-invalid={errors.employerContribution}
                                    />
                                </AboutYouInput>
                            </FieldWithTooltip>
                        </Row>
                        {isMksf ? (
                            <>
                                <Row>
                                    <FieldWithTooltip
                                        label="Insurance premiums paid by you"
                                        tooltipContent={content.insurancePremiumsDisclaimer}
                                        tooltipId={aboutYouTestIds.yourInsurancePremiumsTooltip}
                                        id={aboutYouTestIds.yourInsurancePremiumsInputField}
                                        tooltipLabel="Information about insurance premiums"
                                        tooltipLog="INSURANCE_PREMIUM_TT"
                                        error="Please enter an amount between $0 and $49,999."
                                        isInvalid={errors.insurancePremiums}
                                        help="Please enter the total current annual insurance premiums (including any stamp duty) deducted from your MLC MasterKey account."
                                    >
                                        <AboutYouInput>
                                            <NumberInput
                                                prefix="$"
                                                value={values.insurancePremiums.toString()}
                                                onBlur={(e: any) => {
                                                    setFieldValue("insurancePremiums", convertToNumber(e.target.value));
                                                }}
                                                allowDecimal={false}
                                                allowLeadingZeroes={true}
                                                integerLimit={9}
                                                className={classnames(errors.insurancePremiums ? commonClasses.validationError : '', nonSuperInputsFrozen ? 'disabledForm' : '')}
                                                readOnly={nonSuperInputsFrozen}
                                                aria-readonly={nonSuperInputsFrozen}
                                                data-analytics-capture-value
                                                variant="boxed"
                                                data-id={aboutYouTestIds.yourInsurancePremiumsInput}
                                                aria-labelledby={`${aboutYouTestIds.yourInsurancePremiumsInput}-label`}
                                                aria-describedby={`${aboutYouTestIds.yourInsurancePremiumsInput}-error`}
                                                aria-invalid={errors.insurancePremiums}
                                            />
                                            <AnnuallyText>
                                                annually
                                            </AnnuallyText>
                                        </AboutYouInput>
                                    </FieldWithTooltip>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row>
                                    <FieldWithTooltip
                                        label="Insurance premiums paid by you"
                                        tooltipContent={content.yourPremiumsDisclaimer}
                                        tooltipId={aboutYouTestIds.yourInsurancePremiumsTooltip}
                                        id={aboutYouTestIds.yourInsurancePremiumsInputField}
                                        tooltipLabel="Information about premium paid by members"
                                        tooltipLog="PERSONAL_PAID_PREM_TT"
                                        error="Please enter an amount between $0 and $49,999."
                                        isInvalid={errors.insurancePremiums}
                                    >
                                        <AboutYouInput>
                                            <NumberInput
                                                prefix="$"
                                                value={values.insurancePremiums.toString()}
                                                onBlur={(e: any) => {
                                                    setFieldValue("insurancePremiums", convertToNumber(e.target.value));
                                                }}
                                                allowDecimal={false}
                                                allowLeadingZeroes={true}
                                                integerLimit={9}
                                                className={classnames(errors.insurancePremiums ? commonClasses.validationError : '', nonSuperInputsFrozen ? 'disabledForm' : '')}
                                                readOnly={nonSuperInputsFrozen}
                                                aria-readonly={nonSuperInputsFrozen}
                                                data-analytics-capture-value
                                                data-id={aboutYouTestIds.yourInsurancePremiumsInput}
                                                aria-labelledby={`${aboutYouTestIds.yourInsurancePremiumsInput}-label`}
                                                aria-describedby={`${aboutYouTestIds.yourInsurancePremiumsInput}-error`}
                                                aria-invalid={errors.insurancePremiums}
                                            />
                                            <AnnuallyText>
                                                annually
                                            </AnnuallyText>
                                        </AboutYouInput>
                                    </FieldWithTooltip>
                                </Row>
                                <Row>
                                    <FieldWithTooltip
                                        label="Insurance premiums paid by your employer"
                                        tooltipContent={content.employerPremiumsDisclaimer}
                                        tooltipId={aboutYouTestIds.employerPremiumsTooltip}
                                        tooltipLabel="Information about premium paid by employer"
                                        tooltipLog="EMPLOYER_PAID_PREM_TT"
                                        id={aboutYouTestIds.employerPremiumsInputField}
                                        error="Please enter an amount between $0 and $10,000."
                                        isInvalid={errors.employerInsurancePremium}
                                    >
                                        <AboutYouInput>
                                            <NumberInput
                                                prefix="$"
                                                value={values.employerInsurancePremium.toString()}
                                                onBlur={(e: any) => {
                                                    setFieldValue("employerInsurancePremium", convertToNumber(e.target.value));
                                                }}
                                                allowDecimal={false}
                                                allowLeadingZeroes={true}
                                                integerLimit={9}
                                                className={classnames(errors.employerInsurancePremium ? commonClasses.validationError : '', nonSuperInputsFrozen ? 'disabledForm' : '')}
                                                readOnly={nonSuperInputsFrozen}
                                                aria-readonly={nonSuperInputsFrozen}
                                                data-analytics-capture-value
                                                variant="boxed"
                                                data-id={aboutYouTestIds.employerPremiumsInput}
                                                aria-labelledby={`${aboutYouTestIds.employerPremiumsInput}-label`}
                                                aria-describedby={`${aboutYouTestIds.employerPremiumsInput}-error`}
                                                aria-invalid={errors.employerInsurancePremium}
                                            />
                                            <AnnuallyText>annually</AnnuallyText>
                                        </AboutYouInput>
                                    </FieldWithTooltip>
                                </Row>
                            </>
                        )}
                    </Col>
                    <FieldWrapper
                        label={
                            <>
                                <Label id={aboutYouTestIds.otherIncomeLabel} display="inline">
                                    Do you have any other income?
                                </Label>
                                <InfoPopup label="information about other income" content="Other income could include other employment or taxable income from investments, property and businesses." dataId={aboutYouTestIds.otherIncomeTooltip} log="OTHER_INCOME_QUESTION_TT" />
                            </>
                        }
                        error={"Please enter other income you have or change your selection."}
                        isInvalid={noOtherIncomeError}
                    >
                        <DetailsToggleGroup
                            name="otherIncome"
                            value={localCalculatorValues.hasOtherIncome}
                            onChange={(e: any) => {
                                const val = e.target.value;
                                setTimeout(() => {
                                    if (val === 'y') {
                                        logAction("OTHER_INCOME_QUESTION_YES");
                                    } else {
                                        logAction("OTHER_INCOME_QUESTION_NO");
                                    }
                                    setLocalCalculatorValues({
                                        hasOtherIncome: val
                                    });
                                    if (noOtherIncomeError) setNoOtherIncomeError(false);
                                }, 100);
                            }}
                            data-id={aboutYouTestIds.otherIncomeRadioGroup}
                            aria-labelledby={aboutYouTestIds.otherIncomeLabel}
                            className={classnames(noOtherIncomeError ? commonClasses.validationError : '', nonSuperInputsFrozen ? 'disabled' : '')}
                        >
                            <ToggleButton
                                value="y"
                                data-id={aboutYouTestIds.otherIncomeYes}
                                disabled={nonSuperInputsFrozen}
                                aria-disabled={nonSuperInputsFrozen}
                            >
                                Yes
                            </ToggleButton>
                            <ToggleButton
                                value="n"
                                data-id={aboutYouTestIds.otherIncomeNo}
                                disabled={nonSuperInputsFrozen}
                                aria-disabled={nonSuperInputsFrozen}
                            >
                                No
                            </ToggleButton>
                        </DetailsToggleGroup>
                    </FieldWrapper>
                    {localCalculatorValues.hasOtherIncome === "y" && (
                        <>
                            <Row>
                                <Col>
                                    <FieldWithTooltip
                                        label="Employment income (before tax)"
                                        tooltipContent="Enter any additional income from other employment, including commissions, shift loadings and allowances. It should not include your salary entered above. We have assumed your employer pays the minimum Superannuation Guarantee on this value."
                                        tooltipId={aboutYouTestIds.employmentIncomeTooltip}
                                        tooltipLabel="Information about employment income"
                                        tooltipLog="OTHER_EMPLOYMENT_INCOME_TT"
                                        id={aboutYouTestIds.employmentIncomeInputField}
                                        error={errors.noEmployedIncome}
                                        isInvalid={canShowNoIncomeError && errors.noEmployedIncome}
                                    >
                                        <AboutYouInput>
                                            <NumberInput
                                                prefix="$"
                                                value={values.otherEmploymentIncome.toString()}
                                                onBlur={(e: any) => {
                                                    setFieldValue("otherEmploymentIncome", convertToNumber(e.target.value));
                                                    if (noOtherIncomeError && convertToNumber(e.target.value) > 0) setNoOtherIncomeError(false);
                                                }}
                                                allowDecimal={false}
                                                allowLeadingZeroes={true}
                                                integerLimit={9}
                                                className={classnames((canShowNoIncomeError && errors.noEmployedIncome) ? commonClasses.validationError : '', nonSuperInputsFrozen ? 'disabledForm' : '')}
                                                readOnly={nonSuperInputsFrozen}
                                                aria-readonly={nonSuperInputsFrozen}
                                                data-analytics-capture-value
                                                variant="boxed"
                                                data-id={aboutYouTestIds.employmentIncomeInput}
                                                aria-labelledby={`${aboutYouTestIds.employmentIncomeInput}-label`}
                                                aria-describedby={`${aboutYouTestIds.employmentIncomeInput}-error`}
                                                aria-invalid={canShowNoIncomeError && errors.noEmployedIncome}
                                            />
                                        <AnnuallyText>
                                                annually
                                            </AnnuallyText>
                                        </AboutYouInput>
                                    </FieldWithTooltip>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FieldWithTooltip
                                        label="Other taxable income (before tax)"
                                        error="Please enter an amount between $0 and $250,000."
                                        tooltipContent="Other taxable income includes income from investments, property and businesses. You should subtract any allowable deductions from this value. This amount is used to determine your estimated tax position and is not included in your monthly retirement income. If you require advice on how your other taxable income impacts your retirement income please contact us."
                                        tooltipId={aboutYouTestIds.taxableIncomeTooltip}
                                        tooltipLabel="Information about current taxable income"
                                        tooltipLog="OTHER_TAXABLE_INCOME_TT"
                                        id={aboutYouTestIds.taxableIncomeInputField}
                                        isInvalid={errors.otherTaxableIncome}
                                    >
                                        <AboutYouInput>
                                            <NumberInput
                                                prefix="$"
                                                value={values.otherTaxableIncome.toString()}
                                                onBlur={(e: any) => {
                                                    setFieldValue("otherTaxableIncome", convertToNumber(e.target.value));
                                                    if (noOtherIncomeError && convertToNumber(e.target.value) > 0) setNoOtherIncomeError(false);
                                                }}
                                                allowDecimal={false}
                                                allowLeadingZeroes={true}
                                                integerLimit={9}
                                                className={classnames(errors.otherTaxableIncome ? commonClasses.validationError : '', nonSuperInputsFrozen ? 'disabledForm' : '')}
                                                readOnly={nonSuperInputsFrozen}
                                                aria-readonly={nonSuperInputsFrozen}
                                                data-analytics-capture-value
                                                variant="boxed"
                                                data-id={aboutYouTestIds.taxableIncomeInput}
                                                aria-labelledby={`${aboutYouTestIds.taxableIncomeInput}-label`}
                                                aria-describedby={`${aboutYouTestIds.taxableIncomeInput}-error`}
                                                aria-invalid={errors.otherTaxableIncome}
                                            />                                            
                                        <AnnuallyText>
                                            annually
                                        </AnnuallyText>
                                    </AboutYouInput>
                                    </FieldWithTooltip>
                                </Col>
                            </Row>
                        </>
                    )}
                    <FieldWrapper
                        label="Do you have any other super?"
                        error="Please enter other super you have or change your selection."
                        isInvalid={noOtherSuperError}
                    >
                        <DetailsToggleGroup
                            name="otherSuper"
                            value={localCalculatorValues.hasOtherSuper}
                            onChange={(e: any) => {
                                const val = e.target.value;
                                setTimeout(() => {
                                    if (val === 'y') {
                                        logAction("OTHER_SUPER_QUESTION_YES");
                                    } else {
                                        logAction("OTHER_SUPER_QUESTION_NO");
                                    }
                                    setLocalCalculatorValues({
                                        hasOtherSuper: val
                                    });
                                    if (noOtherSuperError) setNoOtherSuperError(false);
                                }, 100);
                            }}
                            data-id={aboutYouTestIds.otherSuperRadioGroup}
                            className={classnames(noOtherSuperError ? commonClasses.validationError : '', calculatorFrozen ? 'disabled' : '')}
                        >
                            <ToggleButton
                                value="y"
                                data-id={aboutYouTestIds.otherSuperYes}
                                disabled={calculatorFrozen}
                                aria-disabled={calculatorFrozen}
                            >
                                Yes
                            </ToggleButton>
                            <ToggleButton
                                value="n"
                                data-id={aboutYouTestIds.otherSuperNo}
                                disabled={calculatorFrozen}
                                aria-disabled={calculatorFrozen}
                            >
                                No
                            </ToggleButton>
                        </DetailsToggleGroup>
                    </FieldWrapper>
                    {localCalculatorValues.hasOtherSuper === "y" && (
                        <Card>
                            <Grid>
                                <Row>
                                    <OtherSuperDisclaimerCard id={aboutYouTestIds.otherSuperHelp}>
                                        <Paragraph fontSize="inherit" color="inherit" marginBottom="10px">
                                            Any amount entered here will only be used to help provide a projection that considers the
                                            total super you may have available to you in retirement.
                                        </Paragraph>
                                        <Paragraph fontSize="inherit" color="inherit">
                                            <Text fontWeight="semibold" fontSize="inherit">
                                                The Personal Super Calculator is not able to provide advice about
                                                which super fund is appropriate for you.
                                            </Text>
                                            {' '}If you require advice about your other super funds please
                                            contact us so we can refer you to a Financial Adviser.
                                        </Paragraph>
                                    </OtherSuperDisclaimerCard>
                                </Row>
                                <Row>
                                    <FieldWithTooltip
                                        label="How much superannuation do you have in other super funds?"
                                        id={aboutYouTestIds.otherSuperInputField}
                                    >
                                        <AboutYouInput>
                                            <StepInput
                                                prefix="$"
                                                value={Math.trunc(values.otherSuper).toString()}
                                                onBlur={(event: any) => {
                                                    let value = convertToNumber(event.target.value);
                                                    setFieldValue("otherSuper", value);
                                                    if (noOtherSuperError && value > 0) setNoOtherSuperError(false);
                                                }}
                                                allowDecimal={false}
                                                allowLeadingZeroes={true}
                                                integerLimit={9}
                                                className={classnames(calculatorFrozen ? 'disabledForm' : '')}
                                                readOnly={calculatorFrozen}
                                                aria-readonly={calculatorFrozen}
                                                aria-describedby={aboutYouTestIds.otherSuperHelp}
                                                data-analytics-capture-value
                                                variant="boxed"
                                                data-id={aboutYouTestIds.otherSuperInput}
                                                aria-labelledby={`${aboutYouTestIds.otherSuperInput}-label`}
                                            />
                                        </AboutYouInput>
                                    </FieldWithTooltip>
                                </Row>
                            </Grid>
                        </Card>
                    )}
                    <Grid>
                        <Row>
                            <Col md={8} col={12} noGutter={true}>
                                <Card id='monthly-contributions'>
                                    <FieldWrapper
                                        label={
                                            <>
                                                <Label id={aboutYouTestIds.voluntaryContributionsLabel} display="inline">
                                                    Have you made voluntary contributions to any other super account this year?
                                                </Label>
                                                <InfoPopup label="information about voluntary contributions" content={content.voluntaryContributionsInfo} dataId={aboutYouTestIds.voluntaryContributionsInfo} log="VOLUNTARY_CONT_TT" />
                                            </>
                                        }
                                        error="Please choose an option."
                                        isInvalid={monthlyContributionError}
                                    >
                                        <DetailsToggleGroup
                                            name="voluntaryContributions"
                                            value={localCalculatorValues.hasVoluntaryContributions}
                                            onChange={(e: any) => {
                                                const val = e.target.value;
                                                if (val === "y") {
                                                    setIsContributionsMessageOpen(true);
                                                    logAction("VOLUNTARY_CONT_QUESTION_YES");
                                                } else {
                                                    logAction("VOLUNTARY_CONT_QUESTION_NO");
                                                }
                                                setLocalCalculatorValues({
                                                    hasVoluntaryContributions: val
                                                });
                                                setMonthlyContributionError(false);
                                            }}
                                            data-id={aboutYouTestIds.voluntaryContributionsGroup}
                                            aria-labelledby={aboutYouTestIds.voluntaryContributionsLabel}
                                            className={classnames((nonSuperInputsFrozen && localCalculatorValues.hasVoluntaryContributions !== 'y') ? 'disabled' : '')}
                                        >
                                            <ToggleButton
                                                value="y"
                                                data-id={aboutYouTestIds.voluntaryContributionsYes}
                                                ref={contributionsRef}
                                                className={monthlyContributionError ? commonClasses.validationError : null}
                                                disabled={nonSuperInputsFrozen && localCalculatorValues.hasVoluntaryContributions !== 'y'}
                                                aria-disabled={nonSuperInputsFrozen && localCalculatorValues.hasVoluntaryContributions !== 'y'}
                                            >
                                                Yes
                                            </ToggleButton>
                                            <ToggleButton
                                                value="n"
                                                data-id={aboutYouTestIds.voluntaryContributionsNo}
                                                disabled={nonSuperInputsFrozen && localCalculatorValues.hasVoluntaryContributions !== 'y'}
                                                aria-disabled={nonSuperInputsFrozen && localCalculatorValues.hasVoluntaryContributions !== 'y'}
                                            >
                                                No
                                            </ToggleButton>
                                        </DetailsToggleGroup>
                                    </FieldWrapper>
                                </Card>
                            </Col>
                        </Row>

                    </Grid>
                </Card>
                <NavButtonGroup>
                    <NextButton
                        onClick={() => {
                            // only progress if no inline errors
                            const validOtherIncome = localCalculatorValues.hasOtherIncome === 'n' || (localCalculatorValues.hasOtherIncome === 'y' && (values.otherEmploymentIncome > 0 || values.otherTaxableIncome > 0));
                            const validOtherSuper = localCalculatorValues.hasOtherSuper === 'n' || (localCalculatorValues.hasOtherSuper === 'y' && values.otherSuper > 0);
                            if (
                                !errors.employerContribution &&
                                !errors.insurancePremiums &&
                                !errors.employerInsurancePremium &&
                                !errors.otherTaxableIncome &&
                                !errors.noEmployedIncome &&
                                validOtherIncome &&
                                validOtherSuper &&
                                localCalculatorValues.hasVoluntaryContributions //has answered vol con question
                            ) {
                                logAction("NEXT_2");
                                setUpdatingMember();
                            } else {
                                if (errors.noEmployedIncome) setCanShowNoIncomeError(true);
                                if (!validOtherIncome) setNoOtherIncomeError(true);
                                if (!validOtherSuper) setNoOtherSuperError(true);
                                if (!localCalculatorValues.hasVoluntaryContributions) setMonthlyContributionError(true);

                                focusFirstError();
                            }
                        }}
                        data-id={aboutYouTestIds.updateDetailsNext}
                        disabled={localCalculatorValues.hasVoluntaryContributions === 'y'}
                        aria-disabled={localCalculatorValues.hasVoluntaryContributions === 'y'}
                        className={localCalculatorValues.hasVoluntaryContributions === 'y' ? "disabledForm" : ""}
                    >
                        Next
                    </NextButton>
                    <BackButton
                        onClick={() => {
                            logAction("BACK_2")
                            handlePreviousStep();
                        }}
                        data-id={aboutYouTestIds.updateDetailsBack}
                    />
                </NavButtonGroup>
            </Grid >
            <StyledModal
                title="Warning"
                isOpen={isWarningOpen}
                align="left"
                onRequestClose={() => setIsWarningOpen(false)}
                footerButtons={warningContent.btns}
                data-id={aboutYouTestIds.detailsWarningModal}
                shouldCloseOnOverlayClick={false}
            >
                <Card>{warningContent.content}</Card>
            </StyledModal>
            <StyledModal
                isOpen={isSuperMessageOpen}
                onRequestClose={() => setIsSuperMessageOpen(false)}
                title="Warning"
                align="left"
                footerButtons={[
                    {
                        onClick: () => setIsSuperMessageOpen(false),
                        content: "Ok"
                    }
                ]}
                data-id={aboutYouTestIds.otherSuperModal}
                shouldCloseOnOverlayClick={false}
            >
                {content.overSuperThresholdMsg}
            </StyledModal>
            <StyledModal
                title="Warning"
                isOpen={isContributionWarningOpen}
                align="left"
                onRequestClose={() => setIsContributionWarningOpen(false)}
                footerButtons={[
                    {
                        onClick: () => setIsContributionWarningOpen(false),
                        content: "Ok"
                    }
                ]}
                shouldCloseOnOverlayClick={false}
                data-id={aboutYouTestIds.highContributionsModal}
            >
                <Card>{content.warnings.highContributions}</Card>
            </StyledModal>
            <StyledModal
                isOpen={isContributionsMessageOpen}
                onRequestClose={closeContributionsMessage}
                title="Warning"
                align="left"
                footerButtons={[
                    {
                        onClick: closeContributionsMessage,
                        content: "Ok"
                    }
                ]}
                data-id={aboutYouTestIds.contributionsMessageModal}
                shouldCloseOnOverlayClick={false}
            >
                {content.contributionsMessage}
            </StyledModal>
        </>
    )
};

export default AboutYou;
