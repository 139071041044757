import { Card, Col, Grid, Heading, LoadingIndicator, Row, Text, } from '@mlc/web-ui-toolkit';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppThemeContext } from '../../../context/AppThemeContext';
import { RiskProfileMixType } from '../../../enums';
import { Calculator, FrequencyValues } from '../../../logic/domains/calculator.domain';
import { InvestmentOption, Member } from '../../../logic/domains/memberData.domain';
import { logAction } from '../../../logic/functions/logging.function';
import { loadMemberData, updateMemberData } from '../../../logic/functions/memberData.function';
import { generateSoa } from '../../../logic/functions/soa.function';
import AnalyticsForm from '../Common/AnalyticsForm';
import { KateCard } from '../Common/Common.style';
import { MobileDeviceQ } from '../Common/MediaQ';
import { isCalculatorApiEqual, isCochlearPlan, isMySuperOption, mapApiToCalculator, mapCalculatorToApi, shouldHoldInvestments, validateCalculator } from '../../../logic/functions/util';
import SpeedometerSection from '../Common/SpeedometerSection';
import CalculatorSteps from './CalculatorSteps';
import { RetirementGoalsHeadingRow, RetirementGoalsImage, RetirementGoalsText, TriageModal } from './CalculatorSteps/CalculatorSteps.style';
import calculatorTabTestIds from './CalculatorTab.elements';
import { DetailsCard } from './CalculatorTab.style';
import AboutYou from './AboutYou';
import { getTargetSets } from '../../../logic/functions/targetSet.function';
import { TargetSetItem } from '../../../logic/domains/targetSet.domain';

type Props = {
    handleNextStep: () => void;
    handlePreviousStep: () => void;
    memberData?: Member;
    setMemberData: (values: Member) => void;
    stepIndex: number;
    jumpToStep: (index: number) => void;
    localCalculatorValues: any;
    setLocalCalculatorValues: (values: any) => void;
    frequencyValues: FrequencyValues;
}

const CalculatorTab = ({ handleNextStep, handlePreviousStep, setMemberData, memberData, stepIndex, jumpToStep, localCalculatorValues, setLocalCalculatorValues, frequencyValues }: Props) => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [updatingMember, setUpdatingMember] = useState(false);
    const [forceUpdating, setForceUpdating] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [getAdviceOpen, setGetAdviceOpen] = useState(false);
    // value so warnings aren't displayed on first load
    const [canShowDetailsWarnings, setCanShowDetailsWarnings] = useState(false);
    const [calculatorValues, setCalculatorValues] = useState<Calculator>();
    // sets a general frozen (disabled inputs) state for the calculator, fields are unfrozen based on error
    const [calculatorFrozen, setCalculatorFrozen] = useState(false);
    const [adviceModal, setAdviceModal] = useState<any>(undefined);
    const [rpLoading, setRpLoading] = useState(false);
    const [lockNavigation, setlockNavigation] = useState(false);
    const content = useContext(AppThemeContext).staticContent;
    const isMlc = useContext(AppThemeContext).isMlc;

    const nextRef = useRef<any>();
    const backRef = useRef<any>();

    useEffect(() => {
        const setValues = (apiValues: Member) => {
            setMemberData({ ...memberData, ...apiValues });
            const mappedValues = mapApiToCalculator(apiValues);
            setCalculatorValues({ ...calculatorValues, ...mappedValues, ...localCalculatorValues });
        }

        const fetchData = async () => {
            const apiValues = await loadMemberData(memberData?.number);
            setValues(apiValues);
            setInitialLoading(false);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const setValues = (apiValues: Member) => {
            setMemberData({ ...memberData, ...apiValues });
            const mappedValues = mapApiToCalculator(apiValues);
            setCalculatorValues({ ...calculatorValues, ...mappedValues, ...localCalculatorValues });
        }

        const updateData = async () => {
            const errors = validateCalculator({ ...combinedValues });
            // about you page errors are partially handled by triage pop-ups, can cause api to return non-valid values
            const isValid = _.isEmpty(errors) || (stepIndex === 1 && (
                !errors.employerContribution &&
                !errors.insurancePremiums &&
                !errors.employerInsurancePremium &&
                !errors.otherTaxableIncome
            ));
            if (isValid && !loading) {
                setLoading(true);
                const valuesForUpdate = mapCalculatorToApi(combinedValues, memberData!);
                const apiValues = await updateMemberData(valuesForUpdate);
                setValues(apiValues);
                setLoading(false);
                if (stepIndex === 1) {
                    setCanShowDetailsWarnings(true);
                }
                setRpLoading(false);
            }
        }

        const submit = async () => {
            setGetAdviceOpen(false);
            setSubmitting(false);
            setLoading(true);
            await generateSoa(memberData?.number);
            setLoading(false);
            handleNextStep();
        }

        const combinedValues = { ...calculatorValues!, ...localCalculatorValues };
        if (updatingMember) {
            if (!isCalculatorApiEqual(mapCalculatorToApi(combinedValues, memberData!), memberData!) || forceUpdating) {
                updateData();
            } else if (stepIndex === 1) {
                setCanShowDetailsWarnings(true);
            }
            setUpdatingMember(false);
            setForceUpdating(false);
        } else if (submitting) {
            submit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitting, updatingMember]);

    useEffect(() => {
        if (adviceModal !== undefined) {
            if (adviceModal === null) {
                setSubmitting(true);
            } else {
                logAction(adviceModal.log);
                setGetAdviceOpen(true);
            }
        }
    }, [adviceModal])

    const updateMember = () => {
        //delayed to allow formik to complete updates, also stops the pages from switching if update is in progress
        setlockNavigation(true);
        setTimeout(() => {
            setUpdatingMember(true);
            setlockNavigation(false);
        }, 500);
    }

    const closeAdvice = () => {
        const toFocus = nextRef.current!;
        setGetAdviceOpen(false);
        setTimeout(() => {
            toFocus && toFocus.focus();
        }, 100)
    }

    const closeAndProceed = () => {
        setSubmitting(true);
    }

    const closeAndFreeze = () => {
        setCalculatorFrozen(true);
        closeAdvice();
    }

    const getTargetSet = async (): Promise<TargetSetItem[]> => {
        const targetSetData = await getTargetSets();
        return targetSetData ? [...targetSetData] : [];
    }

    // see digital ops manual for more info on triages
    const getAdviceModal = async () => {
        let projectedBalance = Math.round(calculatorValues!.projectedSuper.balance);
        let goalBalance = Math.round(calculatorValues!.goalSuper.balance);
        // checking in case of increased fees for same rp
        let investmentFeeDifference = 0;
        let shouldHold = false;
        //Check if the APIR code for SRI exists in users investment options
        let isSRI = memberData!.currentMix.investmentOptions.some((x) => ["NUL5679AU", "NUL3468AU", "MLCSRGRWTH"].includes(x.option));
        if(isMlc && !memberData?.keepExistingInvestmentMix && calculatorValues?.selectedProfileType?.type === RiskProfileMixType.Growth && memberData?.activeInvestment && isSRI) {
            setLoading(true);
            let targetSetData = await getTargetSet();
            const targetSet = targetSetData[0];
            const currentMix = memberData!.currentMix;
            shouldHold = shouldHoldInvestments(currentMix, targetSetData);
            investmentFeeDifference = targetSet.hasMySuper ? targetSet.mySuperIcrDollar - currentMix.fee.icrDollarAmount : targetSet.fee.icrDollarAmount - currentMix.fee.icrDollarAmount;
            setLoading(false);
        }
        //Need to retain the old check for Plum
        else if(!isMlc && calculatorValues?.selectedProfileType?.type && !memberData?.keepExistingInvestmentMix) {
            setLoading(true);
            let targetSetData = await getTargetSet();
            const targetSet = targetSetData[0];
            const currentMix = memberData!.currentMix;
            shouldHold = shouldHoldInvestments(currentMix, targetSetData);
            investmentFeeDifference = targetSet.hasMySuper ? targetSet.mySuperIcrDollar - currentMix.fee.icrDollarAmount : targetSet.fee.icrDollarAmount - currentMix.fee.icrDollarAmount;
            setLoading(false);
        }
            // triage for plans that selected specific risk profile(s)
        if (!isMlc && !!memberData?.riskProfileTriage) {
            setAdviceModal({
                ...content.calculator().riskProfileTriage,
                title: 'Contact us',
                buttons: [
                    {
                        onClick: closeAndFreeze,
                        content: "Close",
                        buttonType: "primary"
                    },
                ],
                log: memberData.riskProfileTriage,
                onClose: closeAndFreeze
            });
            // non-nabgsf member aged between 63-66 and gets growth from the risk profiler with recommendation to switch into MySuper while not currently in MySuper
        } else if (calculatorValues?.selectedProfileType.type !== null && (memberData!.age >= 63 && memberData!.age <= 66)
            && calculatorValues!.selectedProfileType.type === RiskProfileMixType.Growth && calculatorValues!.selectedMixHasMySuper && !calculatorValues?.isNabGsfMember
            && !memberData?.keepExistingInvestmentMix && !memberData?.currentMix.investmentOptions.some(opt => isMySuperOption(opt.option, false))) {
            setAdviceModal({
                ...content.calculator().genericContactUsTriage,
                buttons: [
                    {
                        onClick: closeAndFreeze,
                        content: "Close",
                        buttonType: "primary"
                    },
                ],
                log: "AGE_GROWTH",
                onClose: closeAndFreeze
            });
        } // current contributions exceed proposed contributions and not reaching super goal
        else if (projectedBalance < goalBalance
            && Math.round(calculatorValues!.proposedContribution.incomeContributedToSuper) < Math.round(calculatorValues!.currentContribution.incomeContributedToSuper)) {
            setAdviceModal({
                ...content.calculator(closeAdvice, jumpToStep).proposedContributionShortfall,
                buttons: [
                    {
                        onClick: closeAndFreeze,
                        content: "Close",
                        buttonType: "primary",
                    },
                ],
                log: "Wait2",
                onClose: closeAndFreeze
            });
            // current = selected risk profile but fees increasing - only for SRI growth
        } else if (isSRI && calculatorValues?.selectedProfileType.type === RiskProfileMixType.Growth && !memberData?.keepExistingInvestmentMix && !shouldHold
            && investmentFeeDifference > 0 && calculatorValues!.selectedProfileType.type === memberData?.currentMix.type) {
            setAdviceModal({
                ...content.calculator().genericContactUsTriage,
                buttons: [
                    {
                        onClick: closeAndFreeze,
                        content: "Close",
                        buttonType: "primary",
                    },
                ],
                log: "SAME_RP_INCREASED_FEES",
                onClose: closeAndFreeze
            });
            // <=1 from retirement (retirement age or from risk profiler question 1) and not switching into cash or maintaining current investments
        } else if (calculatorValues?.selectedProfileType.type !== null && (memberData!.age >= 59 && memberData!.age <= 66) && (calculatorValues!.retirementAge - memberData!.age <= 1 || calculatorValues!.riskRetireIn === 0)
            && (calculatorValues!.selectedProfileType.type !== RiskProfileMixType.Cash) && !memberData?.keepExistingInvestmentMix) {
                setAdviceModal({
                    ...content.calculator().ageRelatedTriage,
                    title: 'Contact us',
                    buttons: [
                        {
                            onClick: closeAndFreeze,
                            content: "Close",
                            buttonType: "primary"
                        },
                    ],
                    log: "AGE_CASH_CHANGE",
                    onClose: closeAndFreeze
                });
            // current cochlear investor, are choosing balanced active and are not keeping existing mix
        } else if (isCochlearPlan(memberData?.currentMix.spin!) && (calculatorValues!.selectedProfileType.type === RiskProfileMixType.Balanced && calculatorValues?.activeInvestment) 
            && !memberData?.keepExistingInvestmentMix ) {
            setAdviceModal({
                ...content.calculator().cochlearTriage,
                title: 'Contact us',
                buttons: [
                    {
                        onClick: closeAndFreeze,
                        content: "Close",
                        buttonType: "primary"
                    },
                ],
                log: "COCHLEAR_ACTBAL_CHANGE",
                onClose: closeAndFreeze
            });
            // <=7 years from retirement, not switching into cash or maintaining current investments, have completed a RP, switching from the recommended RP and in 59-66 year age range
        } else if (calculatorValues?.selectedProfileType.type !== null && (memberData!.age >= 53 && memberData!.age <= 66) && calculatorValues!.retirementAge - memberData!.age <= 7 
            && calculatorValues!.selectedProfileType.type !== RiskProfileMixType.Cash && !memberData?.keepExistingInvestmentMix && calculatorValues?.suggestedProfileType !== calculatorValues?.selectedProfileType.type) {
                setAdviceModal({
                    ...content.calculator().ageRelatedSoftWarn(memberData!.selectedMix, memberData!.riskProfileMixType == null ? "" : memberData!.riskProfileMixType),
                    buttons: [
                        {
                            onClick: closeAdvice,
                            content: "Review my selections",
                            buttonType: "tertiary"
                        },
                        {
                            onClick: closeAndProceed,
                            content: "Accept and proceed",
                            buttonType: "primary",
                        }
                    ],
                    log: "AGE_SOFT_WARN",
                    onClose: closeAdvice
                });
        }
        else {
            setAdviceModal(null);
        }
    }

    const proposedMaxCcCont = memberData?.proposedCcCap;

    return (
        <>
            <LoadingIndicator isOpen={initialLoading} message="Just a moment..." variant="fullscreen" data-id={calculatorTabTestIds.calculatorInitialLoadingIndicator} />
            {!initialLoading && (
                <>
                    <LoadingIndicator isOpen={loading || rpLoading} message="Just a moment..." variant="fullscreen" data-id={calculatorTabTestIds.calculatorLoadingIndicator} />
                    <Card marginBottom={7}>
                        <Formik
                            initialValues={calculatorValues!}
                            onSubmit={(values: Calculator) => {
                                getAdviceModal();
                            }}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validateOnMount={true}
                            validate={(values: Calculator) => validateCalculator(values)}
                            enableReinitialize={true}
                        >
                            {(formikProps) => {
                                const { handleSubmit, values } = formikProps;
                                if (!loading && !_.isEqual(values, calculatorValues)) {
                                    setTimeout(() => {
                                        setCalculatorValues(values);
                                    }, 100);
                                }

                                const calcStep = (
                                    <>
                                        <Grid>
                                            <CalculatorSteps
                                                formikProps={{ ...formikProps, errors: validateCalculator({ ...values }), rpLoading, setRpLoading }}
                                                handleNextStep={handleNextStep}
                                                handlePreviousStep={handlePreviousStep}
                                                setUpdatingMember={updateMember}
                                                nextRef={nextRef}
                                                backRef={backRef}
                                                allFrozen={calculatorFrozen}
                                                setForceUpdating={() => setForceUpdating(true)}
                                                stepIndex={stepIndex}
                                                jumpToStep={jumpToStep}
                                                setLocalCalculatorValues={setLocalCalculatorValues}
                                                lockNavigation={lockNavigation}
                                                frequencyValues={frequencyValues}
                                                proposedMaxCcCont={proposedMaxCcCont ? proposedMaxCcCont : 0}
                                            />
                                        </Grid>
                                    </>
                                )
                                return (
                                    // need to split it to make the analytics scripts work with the form tags correctly...
                                    <>
                                        {stepIndex === 0 &&(
                                            <AnalyticsForm stepIndex={stepIndex}>
                                                <AboutYou
                                                    formikProps={{ ...formikProps, errors: validateCalculator({ ...values, ...localCalculatorValues }) }}
                                                    setUpdatingMember={updateMember}
                                                    handleNextStep={handleNextStep}
                                                    handlePreviousStep={handlePreviousStep}
                                                    calculatorFrozen={calculatorFrozen || localCalculatorValues.hasVoluntaryContributions === "y"}
                                                    localCalculatorValues={localCalculatorValues}
                                                    setLocalCalculatorValues={setLocalCalculatorValues}
                                                    canShowDetailsWarnings={canShowDetailsWarnings}
                                                    setCanShowDetailsWarnings={setCanShowDetailsWarnings}
                                                    spin={memberData!.currentMix.spin || ""}
                                                />
                                            </AnalyticsForm>
                                        )}

                                        {stepIndex === 1 &&(
                                            <AnalyticsForm stepIndex={stepIndex}>
                                                <AboutYou
                                                    formikProps={{ ...formikProps, errors: validateCalculator({ ...values, ...localCalculatorValues }) }}
                                                    setUpdatingMember={updateMember}
                                                    handleNextStep={handleNextStep}
                                                    handlePreviousStep={handlePreviousStep}
                                                    calculatorFrozen={calculatorFrozen || localCalculatorValues.hasVoluntaryContributions === "y"}
                                                    localCalculatorValues={localCalculatorValues}
                                                    setLocalCalculatorValues={setLocalCalculatorValues}
                                                    canShowDetailsWarnings={canShowDetailsWarnings}
                                                    setCanShowDetailsWarnings={setCanShowDetailsWarnings}
                                                    spin={memberData!.currentMix.spin || ""}
                                                />
                                            </AnalyticsForm>
                                        )}
                                        
                                        {stepIndex === 2 && (
                                            <AnalyticsForm stepIndex={stepIndex}>
                                                <KateCard marginBottom="15px" paddingBottom="5px">
                                                    <Grid>
                                                        <MobileDeviceQ
                                                            content={
                                                                <RetirementGoalsHeadingRow>
                                                                    <Col col={9}>
                                                                        <Row>
                                                                            <Col>
                                                                                <Heading marginTop="0px" fontSize="large" el="span" fontWeight="semibold" display="block">
                                                                                    What type of retirement are you looking for {values.firstName}?
                                                                                </Heading>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <Text marginTop="10px">
                                                                                    Let's explore your goals.
                                                                                </Text>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col col={3}>
                                                                        <RetirementGoalsImage>
                                                                            <img alt="" src={content.retirementGoals.umbrella} />
                                                                        </RetirementGoalsImage>
                                                                    </Col>
                                                                </RetirementGoalsHeadingRow>
                                                            }
                                                            mobileContent={
                                                                <Card paddingTop="15px" paddingBottom="10px">
                                                                    <Row>
                                                                        <RetirementGoalsText>
                                                                            <Col col={8}>
                                                                                <Heading fontSize="large" el="span" fontWeight="semibold" display="block">
                                                                                    What type of retirement are you looking for {values.firstName}?
                                                                                </Heading>
                                                                            </Col>
                                                                            <Col col={4}>
                                                                                <RetirementGoalsImage>
                                                                                    <img alt="" src={content.retirementGoals.umbrella} />
                                                                                </RetirementGoalsImage>
                                                                            </Col>
                                                                        </RetirementGoalsText>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Text marginTop="10px">
                                                                                Let's explore your goals.
                                                                            </Text>
                                                                        </Col>
                                                                    </Row>
                                                                </Card>
                                                            }
                                                        />
                                                    </Grid>
                                                </KateCard>
                                                {calcStep}
                                            </AnalyticsForm>
                                        )}

                                        {stepIndex === 3 && (
                                            <AnalyticsForm stepIndex={stepIndex}>
                                                {calcStep}
                                            </AnalyticsForm>
                                        )}

                                        {stepIndex === 4 && (
                                            <AnalyticsForm stepIndex={stepIndex}>
                                                {calcStep}
                                            </AnalyticsForm>
                                        )}

                                        {stepIndex === 5 && (
                                            <AnalyticsForm stepIndex={stepIndex} onSubmit={handleSubmit}>
                                                <DetailsCard>
                                                    <Grid>
                                                        <Row>
                                                            <Col col={12}>
                                                                <SpeedometerSection data={formikProps.values} />
                                                            </Col>
                                                        </Row>
                                                    </Grid>
                                                </DetailsCard>
                                                {calcStep}
                                            </AnalyticsForm>
                                        )}

                                    </>
                                )
                            }}
                        </Formik>
                        {adviceModal && (
                            <TriageModal
                                isOpen={getAdviceOpen}
                                onRequestClose={() => {
                                    adviceModal.onClose && adviceModal.onClose();
                                    closeAdvice();
                                }}
                                title={adviceModal.title}
                                align="left"
                                footerButtons={adviceModal.buttons}
                                shouldCloseOnOverlayClick={false}
                                data-id={calculatorTabTestIds.adviceModal}
                                colorTheme={adviceModal.title ? "brandColor" : "mlcWhite"}
                            >
                                {adviceModal.content}
                            </TriageModal>
                        )}
                    </Card>
                </>
            )
            }
        </>
    );
};

export default CalculatorTab;