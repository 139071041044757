import { Card, Col, FieldWrapper, Grid, Heading, Input, PrimaryButton, Row, TertiaryButton, Text, Textarea, ToggleButton, ToggleButtonGroup } from "@mlc/web-ui-toolkit";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ContactType, ScreenSize } from "../../../../enums";
import { NpsResponse } from "../../../../logic/domains/npsResponse.domain";
import { formatCustomerName } from "../../../../logic/functions/formatter";
import { submitNps } from "../../../../logic/functions/nps.function";
import { focusFirstError, validateNps } from "../../../../logic/functions/util";
import { commonClasses } from "../../Common/Common.elements";
import MediaQ from "../../Common/MediaQ";
import npsTestIds from "./NPS.elements";
import { CloseNps, DesktopScoreAndText, NPSButtonRow, NPSContainer, NPSEmailInput, NPSFieldWrapper, NPSHeader, NPSOtherFields, NPSRatingWrapper, NPSScoreButton, NPSScoreGroup, NPSScoreTextMobile, NPSScoreTextMobileBottom, StickyNPSFooter } from "./NPS.style";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    setShouldDisplayNps: (open: boolean) => void;
    forceCloseNps: boolean;
    name: string;
}

const NPS = ({ isOpen, setIsOpen, setShouldDisplayNps, forceCloseNps, name }: Props) => {
    const handleNpsClose = () => {
        setIsOpen(false);
        setShouldDisplayNps(false);
        sessionStorage.setItem("npsDone", "true");
    }
    const [shouldShowErrors, setShouldShowErrors] = useState(false);
    const [shouldShowContactErrors, setShouldShowContactErrors] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [npsValues, setNpsValues] = useState<NpsResponse>(
        {
            rating: "",
            feedback: "",
            contactType: undefined,
            email: ""
        }
    )
    const [showAllContent, setShowAllContent] = useState(false); 

    const nums = _.fill(Array(11), null);

    const hearMoreText = (
        <>
        <Text>
            <b>Thanks {formatCustomerName(name)}</b>, we'd love to hear more about your experience today so we can continue to improve our calculator.
        </Text>
        </>
    )

    useEffect(() => {
        setErrors(validateNps(npsValues));
    }, [npsValues]);

    const NPSScoreSection = (
        <Card>
            <NPSScoreGroup
                name="rating"
                value={npsValues.rating}
                onChange={(e: any) => {
                    setNpsValues({
                        ...npsValues,
                        rating: e.target.value
                    });
                setShowAllContent(true);
                }}
                data-id={npsTestIds.npsRating}
                id="field-nps-score"
                aria-labelledby="label-nps-score"
                aria-describedby="error-nps-score"
                isInvalid={shouldShowErrors && errors.rating}
            >
                {nums.map((i, idx) => {
                    return (
                        <NPSScoreButton
                            value={idx.toString()}
                            data-id={`nps-rating-${idx}`}
                            key={`rating-${idx}`}
                            className={errors.rating ? commonClasses.validationError : null}>
                            {idx}
                        </NPSScoreButton>
                    );
                })}
            </NPSScoreGroup>
        </Card>
    )

    return (
        <StickyNPSFooter data-id="nps-footer" className={classnames(isOpen ? "isOpen" : "", forceCloseNps ? "forceClose" : "")} >
            <Col>
                <Grid>
                    <NPSHeader>
                        <Grid>
                            <Heading textAlign="center" color="white" fontSize="larger" el="h2">
                                We value your feedback              
                                <CloseNps className="nps-close" onClick={() =>{handleNpsClose();}}> 
                                    <FontAwesomeIcon size="lg" icon="plus-circle"/>
                                </CloseNps>
                            </Heading>
                        </Grid>
                    </NPSHeader>
                    <NPSContainer>
                        <Grid>
                        <NPSRatingWrapper>
                            <Row>
                                <Col noGutter={true}>
                                    <NPSFieldWrapper
                                        label="How likely are you to recommend using the Personal Super Calculator to a friend or colleague?"
                                        error="Please choose an option."
                                        isInvalid={shouldShowErrors && errors.rating}
                                        id="nps-score"
                                    >
                                        <>
                                            <MediaQ
                                                smallWidth={ScreenSize.xSmall}
                                                content={
                                                    <>
                                                        <DesktopScoreAndText>
                                                            <Text fontSize="14px">Not at all likely</Text>
                                                            {NPSScoreSection}
                                                            <Text fontSize="14px">Extremely likely</Text>
                                                        </DesktopScoreAndText>
                                                    </>
                                                }
                                                mobileContent={
                                                    <>
                                                        <NPSScoreTextMobile>Not at all likely</NPSScoreTextMobile>
                                                        {NPSScoreSection}
                                                        <NPSScoreTextMobileBottom>Extremely likely</NPSScoreTextMobileBottom>
                                                    </>
                                                }
                                            />
                                        </>
                                    </NPSFieldWrapper>
                                </Col>
                            </Row>
                        </NPSRatingWrapper>
                        <NPSOtherFields className={classnames(showAllContent === true ? "displayed" : "")}>
                            <Col>
                                    <FieldWrapper
                                        label={hearMoreText}
                                        counterCurrent={npsValues.feedback.length}
                                        help="Character limit 2500"
                                        error="Comment is over character limit"
                                        isInvalid={shouldShowContactErrors && errors.feedback}
                                    >
                                        <Textarea
                                            onChange={(e: any, { value }: any) => {
                                                setNpsValues({
                                                    ...npsValues,
                                                    feedback: value.slice(0, 2500)
                                                });
                                            }}
                                            placeholder="What is your reason for providing this score?"
                                            value={npsValues.feedback}
                                            data-id={npsTestIds.npsFeedback}
                                            rows={2}
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper
                                        label="Do you want to be contacted about your feedback?"
                                        error="Please choose an option."
                                        isInvalid={shouldShowErrors && errors.contactType}
                                    >
                                        <ToggleButtonGroup
                                            name="contactType"
                                            value={npsValues.contactType}
                                            onChange={(e: any) => {
                                                setNpsValues({
                                                    ...npsValues,
                                                    contactType: e.target.value
                                                });
                                                setShouldShowContactErrors(false);
                                            }
                                            }
                                            data-id={npsTestIds.contactType}
                                        >
                                            <ToggleButton
                                                value={ContactType.email}
                                                data-id={npsTestIds.contactEmail}
                                            >
                                                Yes
                                            </ToggleButton>
                                            <ToggleButton
                                                value={ContactType.none}
                                                data-id={npsTestIds.contactNone}
                                            >
                                                No
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </FieldWrapper>
                                {npsValues.contactType === ContactType.email && (
                                    <FieldWrapper
                                        error={errors.email}
                                        isInvalid={shouldShowContactErrors && errors.email}
                                    >
                                        <NPSEmailInput
                                            onChange={(e: any) => {
                                                setNpsValues({
                                                    ...npsValues,
                                                    email: e.target.value
                                                });
                                            }}
                                            placeholder="Your email address"
                                            value={npsValues.email}
                                            className={errors.email ? commonClasses.validationError : null}
                                            data-id={npsTestIds.npsEmail}
                                        />
                                    </FieldWrapper>
                                )}
                                 <MediaQ
                                    smallWidth={ScreenSize.xSmall}
                                    content={
                                        <>            
                                        <NPSButtonRow>
                                            <TertiaryButton type="button" onClick={() => handleNpsClose()}>
                                                Close
                                            </TertiaryButton>   
                                            <PrimaryButton
                                                type="button"
                                                onClick={() => {
                                                    setShouldShowErrors(true);
                                                    setShouldShowContactErrors(true);
                                                    if (_.isEmpty(errors)) {
                                                        if (npsValues.contactType === ContactType.email) {
                                                            setIsSubmitted(true);
                                                            setShouldDisplayNps(false);
                                                            setIsOpen(false);
                                                            sessionStorage.setItem("npsDone", "true");
                                                        } else {
                                                            handleNpsClose();
                                                        }
                                                        submitNps(npsValues);
                                                    } else {
                                                        focusFirstError();
                                                    }
                                                }}
                                                id="submit-button"
                                                >
                                                Submit
                                            </PrimaryButton>
                                        </NPSButtonRow>
                                        </>}
                                    mobileContent={
                                        <>
                                        <NPSButtonRow>
                                            <PrimaryButton
                                                type="button"
                                                onClick={() => {
                                                    setShouldShowErrors(true);
                                                    setShouldShowContactErrors(true);
                                                    if (_.isEmpty(errors)) {
                                                        if (npsValues.contactType === ContactType.email) {
                                                            setIsSubmitted(true);
                                                            setShouldDisplayNps(false);
                                                            setIsOpen(false);
                                                            sessionStorage.setItem("npsDone", "true");
                                                        } else {
                                                            handleNpsClose();
                                                        }
                                                        submitNps(npsValues);
                                                    } else {
                                                        focusFirstError();
                                                    }
                                                }}
                                                id="submit-button"
                                                >
                                                Submit
                                            </PrimaryButton>
                                            <TertiaryButton type="button" onClick={() => handleNpsClose()}>
                                                Close
                                            </TertiaryButton>   
                                        </NPSButtonRow>
                                    </>}
                                />
                            </Col>
                        </NPSOtherFields>
                        </Grid>
                    </NPSContainer>
                </Grid>
            </Col>
        </StickyNPSFooter>
    );
}

export default NPS;